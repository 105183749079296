<template>
  <div>
    <template v-for="(alert, index) in value.alarms">
      <form-subheader :key="`header_${index}`" :title="`Alarm ${index + 1}`" />
      <div :key="`alert_${index}`" class="alarm">
        <form-subheader title="Alarm condition" class="text--secondary" />
        <div
          v-if="objectItemType === objectItemTypes.monitoringItem"
          class="d-flex flex-nowrap"
        >
          <v-select
            v-testid="`alarm-${index}-condition-operator`"
            :value="alert.condition.operator"
            :items="operators"
            clearable
            class="mr-4"
            style="flex-basis: 60%"
            @input="onInput(index, 'condition.operator', $event)"
          />
          <v-text-field
            v-testid="`alarm-${index}-condition-input`"
            :disabled="alert.condition.operator === DOES_NOT_CHANGE_OPERATOR"
            :value="alert.condition.value"
            style="flex-basis: 40%"
            @input="onInput(index, 'condition.value', $event)"
          />
        </div>
        <div v-else>
          <v-select
            v-testid="`alarm-${index}-condition-type`"
            :value="alert.condition.type"
            label="Alert type"
            clearable
            :items="Object.keys(GEO_ALARM_TYPES)"
            @input="onInput(index, 'condition.type', $event)"
          />
          <div
            v-if="alert.condition.type === GEO_ALARM_TYPES.distance"
            class="d-flex flex-nowrap"
          >
            <v-select
              v-testid="`alarm-${index}-condition-operator`"
              :value="alert.condition.operator"
              :items="operators"
              class="mr-4"
              @input="onInput(index, 'condition.operator', $event)"
            />
            <v-text-field
              v-testid="`alarm-${index}-condition-input`"
              :value="Number(alert.condition.value)"
              @input="onInput(index, 'condition.value', Number($event))"
            />
          </div>
          <div v-else class="d-flex flex-nowrap">
            <v-select
              v-testid="`alarm-${index}-condition-select`"
              label="Value"
              :value="Boolean(alert.condition.value)"
              item-text="name"
              item-value="value"
              clearable
              :items="[
                { name: 'Inside', value: true },
                { name: 'Outside', value: false }
              ]"
              @input="onInput(index, 'condition.value', Boolean($event))"
            />
          </div>
        </div>
        <form-switcher
          v-testid="`alarm-${index}-timer-switcher`"
          title="Triggering options"
          :value="alert.isTimerAndSchedule"
          @input="onInput(index, 'isTimerAndSchedule', $event)"
        />
        <template v-if="alert.isTimerAndSchedule">
          <form-subheader title="Schedule" class="text--secondary" />
          <div class="d-flex flex-column">
            <div class="d-flex flex-nowrap align-center">
              <div style="width: 60px;" class="flex-shrink-0">From</div>
              <v-select
                v-testid="`alarm-${index}-time-interval-from`"
                :value="getTime(+alert.timeIntervalInMinutes.from, 'hours')"
                :items="hours"
                class="mr-4"
                suffix="h"
                @input="
                  onInput(
                    index,
                    'timeIntervalInMinutes.from',
                    $event * 60 + (+alert.timeIntervalInMinutes.from % 60)
                  )
                "
              />
              <v-select
                v-testid="`alarm-${index}-time-interval-from-units`"
                :value="getTime(+alert.timeIntervalInMinutes.from, 'minutes')"
                :items="minutes"
                suffix="min"
                @input="
                  onInput(
                    index,
                    'timeIntervalInMinutes.from',
                    Math.floor(+alert.timeIntervalInMinutes.from / 60) * 60 +
                      +$event
                  )
                "
              />
            </div>
            <div class="d-flex flex-nowrap  align-center">
              <div style="width: 60px;" class="flex-shrink-0">To</div>
              <v-select
                v-testid="`alarm-${index}-time-interval-to`"
                :value="getTime(+alert.timeIntervalInMinutes.to, 'hours')"
                :items="hours"
                class="mr-4"
                suffix="h"
                @input="
                  onInput(
                    index,
                    'timeIntervalInMinutes.to',
                    +$event * 60 + (+alert.timeIntervalInMinutes.to % 60)
                  )
                "
              />
              <v-select
                v-testid="`alarm-${index}-time-interval-to-units`"
                :value="getTime(+alert.timeIntervalInMinutes.to, 'minutes')"
                :items="minutes"
                suffix="min"
                @input="
                  onInput(
                    index,
                    'timeIntervalInMinutes.to',
                    Math.floor(alert.timeIntervalInMinutes.to / 60) * 60 +
                      +$event
                  )
                "
              />
            </div>
            <div class="d-flex flex-nowrap align-center my-2">
              <v-btn-toggle
                v-testid="`alarm-${index}-weekdays-btn-toggle`"
                class="flex-grow-1"
                :value="alert.weekDays"
                multiple
                @change="onInput(index, 'weekDays', $event)"
              >
                <v-btn
                  v-for="(weekDay, idx) in weekDayNames"
                  :key="idx"
                  v-testid="`alarm-${index}-weekdays-btn-${idx}`"
                  :color="
                    alert.weekDays.includes(idx) ? 'primary white--text' : ''
                  "
                  class="flex-grow-1"
                  v-text="weekDay"
                />
              </v-btn-toggle>
            </div>
          </div>
          <form-subheader
            title="Alarm condition met for"
            class="text--secondary"
          />
          <div class="d-flex flex-nowrap">
            <v-text-field
              v-testid="`alarm-${index}-timer-timeout`"
              :value="alert.timeout.value"
              class="mr-4"
              @input="onInput(index, 'timeout.value', Number($event))"
            />
            <v-select
              v-testid="`alarm-${index}-timer-units`"
              :value="alert.timeout.units"
              :items="timeUnits"
              @input="onInput(index, 'timeout.units', $event)"
            />
          </div>
        </template>
        <form-switcher
          v-testid="`alarm-${index}-timer-dismiss-switcher`"
          title="Dismissing options"
          :value="alert.timer.dismiss"
          @input="onInput(index, 'timer.dismiss', $event)"
        />
        <template v-if="alert.timer.dismiss">
          <form-subheader
            title="Alarm condition met for"
            class="text--secondary"
          />
          <div class="d-flex flex-nowrap">
            <v-text-field
              v-testid="`alarm-${index}-timer-dismiss-value`"
              :value="alert.timer.value"
              class="mr-4"
              @input="onInput(index, 'timer.value', Number($event))"
            />
            <v-select
              v-testid="`alarm-${index}-timer-dismiss-units`"
              :value="alert.timer.units"
              :items="timeUnits"
              @input="onInput(index, 'timer.units', $event)"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash-es';
import {
  getTime,
  operatorsByType,
  timeUnits,
  hours,
  minutes,
  objectItemTypes
} from './item-helpers';
import { monitoringItemTypes } from '@/modules/object-types/compositions/constants';
import { computed, toRefs } from '@vue/composition-api';
import { GEO_ALARM_TYPES } from './item-helpers';
import { getDefaultTimerAndSchedule } from '@/modules/objects/ui/object-card/general/monitoring-item-helpers';

const DOES_NOT_CHANGE_OPERATOR = 'does not change';
export default {
  name: 'ObjectItemAlarms',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: monitoringItemTypes.string
    },
    objectItemType: {
      type: String
    }
  },
  computed: {
    objectItemTypes() {
      return objectItemTypes;
    }
  },
  setup(props, { emit }) {
    const weekDayNames = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    const { type } = toRefs(props);
    const onInput = (index, path, value) => {
      const clone = _.cloneDeep(props.value);
      if (path === 'isTimerAndSchedule' && !value) {
        clone.alarms[index] = {
          ...clone.alarms[index],
          ...getDefaultTimerAndSchedule()
        };
      }
      if (path === 'condition.type') {
        if (value === GEO_ALARM_TYPES.distance) {
          _.set(clone.alarms[index], 'condition.value', 0);
        } else {
          _.set(clone.alarms[index], 'condition.value', false);
        }
      }
      if (path === 'condition.operator' && value === DOES_NOT_CHANGE_OPERATOR) {
        _.set(clone.alarms[index], 'condition.value', 0);
        emit('input', clone);
      }
      _.set(clone.alarms[index], path, value);
      emit('input', clone);
    };

    const operators = computed(() => {
      if (props.objectItemType === objectItemTypes.geoItem)
        return operatorsByType[monitoringItemTypes.number];
      return (
        operatorsByType[type.value] ||
        operatorsByType[monitoringItemTypes.string]
      );
    });

    return {
      operators,
      timeUnits,
      hours,
      minutes,
      getTime,
      onInput,
      DOES_NOT_CHANGE_OPERATOR,
      weekDayNames,
      GEO_ALARM_TYPES
    };
  }
};
</script>

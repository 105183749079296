export const getDefaultTimerAndSchedule = () => ({
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  timeout: {
    value: '',
    units: 'seconds'
  },
  timeIntervalInMinutes: {
    from: 0,
    to: 0
  }
});

export const defaultAlarm = () => ({
  isTimerAndSchedule: false,
  timer: {
    dismiss: false,
    value: 10,
    units: 'minutes'
  },
  condition: {
    operator: '',
    value: 0
  },
  ...getDefaultTimerAndSchedule()
});

import { monitoringItemTypes } from '@/modules/object-types/compositions/constants';

export const operatorsByType = {
  [monitoringItemTypes.string]: [
    '>',
    '<',
    '=',
    '!=',
    'contains',
    'does not change',
    'regex',
    'is empty',
    'is not empty',
    '>=',
    '<='
  ],
  [monitoringItemTypes.number]: [
    '>',
    '<',
    '=',
    '!=',
    'contains',
    'does not change',
    'regex',
    'is empty',
    'is not empty',
    '>=',
    '<='
  ],
  [monitoringItemTypes.bool]: [
    '>',
    '<',
    '=',
    '!=',
    'contains',
    'does not change',
    'regex',
    'is empty',
    'is not empty',
    '>=',
    '<='
  ],
  double: [
    '>',
    '<',
    '=',
    '!=',
    'contains',
    'does not change',
    'regex',
    'is empty',
    'is not empty',
    '>=',
    '<='
  ],
  int: [
    '>',
    '<',
    '=',
    '!=',
    'contains',
    'does not change',
    'regex',
    'is empty',
    'is not empty',
    '>=',
    '<='
  ]
};

export const objectItemTypes = {
  monitoringItem: 'monitoringItem',
  geoItem: 'geoItem'
};
export const timeUnits = ['minutes', 'times'];
export const addZero = value => (value < 10 ? `0${value}` : value);

export const hours = new Array(24).fill().map((_, index) => addZero(index));
export const minutes = new Array(60).fill().map((_, index) => addZero(index));

export const getTime = (valueInMinutes, units) => {
  return addZero(
    units === 'hours' ? Math.floor(valueInMinutes / 60) : valueInMinutes % 60
  );
};

export const GEO_ALARM_TYPES = {
  distance: 'distance',
  position: 'position'
};

export const defaultGeoAlarm = () => ({
  isTimerAndSchedule: false,
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  timer: {
    dismiss: false,
    value: 10,
    units: 'minutes'
  },
  condition: {
    type: GEO_ALARM_TYPES.position,
    operator: '>',
    value: 0
  },
  timeout: {
    value: 0,
    units: 'seconds'
  },
  timeIntervalInMinutes: {
    from: 0,
    to: 0
  }
});

export const defaultMonitoringAlarm = () => ({
  isTimerAndSchedule: false,
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  timer: {
    dismiss: false,
    value: 10,
    units: 'minutes'
  },
  condition: {
    operator: '',
    value: 0
  },
  timeout: {
    value: 0,
    units: 'seconds'
  },
  timeIntervalInMinutes: {
    from: 0,
    to: 0
  }
});

export const defaultMonitoringRepeat = () => ({
  enabled: false,
  units: 'minutes',
  value: 0
});
